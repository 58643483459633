import type { KachingRenderInitData } from '@hubcms/domain-ads';

import { Ad } from '../Ad/Ad';

type LeaderboardAdProps = {
  adFormat?: string;
  className?: string;
  onRenderInit?: (data: KachingRenderInitData) => void;
};

export const LeaderboardAd = ({ adFormat = 'leaderboard', className, onRenderInit }: LeaderboardAdProps) => (
  <Ad adFormat={adFormat} adSlot="t1" className={className} onRenderInit={onRenderInit} />
);
